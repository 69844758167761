<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :width="width"
    :modal="true"
    :show-close="false"
    :append-to-body="true"
    :before-close="handleClose"
    :custom-class="customClass"
    :closeOnClickModal="true"
    @open="(e) => $emit('open', e)"
  >
    <slot></slot>
  </el-dialog>
</template>

<script>
export default {
  name: "RuoyiUiDialog",

  props: {
    // 对话框宽度
    width: {
      typeof: String,
      default: "500px",
    },
    // 对话框是否显示
    dialogVisible: {
      typeof: Boolean,
      default: false,
    },
    // 传递过来的类名
    customClass: {
      typeof: String,
      default: "my-dialog",
    },
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {
    handleClose(done) {
      this.closeDialog();
    },
    // 关闭对话框
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: 0 !important;
}

::v-deep .el-dialog__body {
  width: 100% !important;
  height: 100%;
  padding: 0 !important;
}
</style>
