<template>
  <div class="rightlevel">
    <div class="hea">
      <div class="l">
        <img src="@/assets/images/line2.png" alt="" />
        <span>维保内容</span>
      </div>
      <div class="r">
        <el-button type="primary">提交</el-button>
        <el-button>关闭</el-button>
      </div>
    </div>
    <div class="bodu">
      <el-form
        :model="ruleForm"
        :status-icon="false"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
        <div class="item">
          <!-- 编号 -->
          <el-form-item label="客户名称:" required prop="deviceNumber">
            <el-input
              type="number"
              maxlength="20"
              v-model="ruleForm.deviceNumber"
              placeholder="请输入"
              autocomplete="off"
              oninput="value=value.replace(/^(0+)|[^\d]+/g, '').slice(0, 20)"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 编号结束 -->
        <div class="right"></div>
        <!-- 设备型号 -->
        <div class="item">
          <el-form-item
            class="mb20"
            label="所属基地:"
            required
            prop="deviceModel"
          >
            <el-row class="check" type="flex" align="middle">
              <el-input
                type="text"
                maxlength="20"
                v-model="ruleForm.workShopData"
                placeholder="请输入联系人"
                autocomplete="off"
              ></el-input>
            </el-row>
          </el-form-item>
        </div>
        <div class="r">
          <el-button type="primary">搜索</el-button>
          <el-button>重置</el-button>
        </div>
      </el-form>
      <!-- 表格展示 start -->
      <el-row class="table-box">
        <el-table
          :header-cell-style="{ background: '#F4F4F4', height: '56px' }"
          :data="tableData"
          style="width: 100%"
          stripe
          header-cell-class-name="table-header"
          :key="Math.random()"
        >
          <el-table-column
            prop="frontIndex"
            label="序号"
            width="54"
            :key="Math.random()"
          >
          </el-table-column>
          <el-table-column
            prop="num"
            label="标准名称"
            width="125"
            :key="Math.random()"
          >
            <template slot-scope="{ row }">{{ row.num || "-" }}</template>
          </el-table-column>
          <el-table-column
            prop="type"
            label="标准类型"
            width="126"
            :key="Math.random()"
          >
            <template slot-scope="{ row }">{{
              `${row.deviceTypeName}-${row.deviceTypeParentName}` || "-"
            }}</template>
          </el-table-column>
          <el-table-column
            prop="make"
            label="保养部位"
            width="156"
            :key="Math.random()"
          >
            <template slot-scope="{ row }">{{ row.make || "-" }}</template>
          </el-table-column>
          <el-table-column
            prop="modal"
            label="保养内容"
            width="159"
            :key="Math.random()"
          >
            <template slot-scope="{ row }">{{ row.modal || "-" }}</template>
          </el-table-column>
          <el-table-column prop="modal" label="适用设备" :key="Math.random()">
            <template slot-scope="{ row }">{{ row.modal || "-" }}</template>
          </el-table-column>
          <el-table-column prop="modal" label="操作指南" :key="Math.random()">
            <template slot-scope="{ row }">{{ row.modal || "-" }}</template>
          </el-table-column>
        </el-table>
      </el-row>

      <!-- 表格展示 end -->
      <!-- 分页开始 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="listTotal"
        :page-size="pageSize"
        :current-page="page"
        @current-change="pageChange"
        @prev-click="prevChange"
        @next-click="nextChange"
      >
      </el-pagination>
      <!-- 分页结束 -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validateId = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入客户名称"));
      }
      callback();
    };
    var validateType = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择省"));
      }
      callback();
    };
    var validateBrand = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择市"));
      }
      callback();
    };
    var validateModel = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入所属基地"));
      }
      callback();
    };
    var validateDate = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入故障现象"));
      }
      callback();
    };
    var validatePos = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入地区"));
      }
      callback();
    };
    var validateAddress = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入地址"));
      }
      callback();
    };
    var validateState = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择项目经理"));
      }
      callback();
    };
    var validateWorkShop = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系人"));
      }
      callback();
    };
    return {
      tableData: [{}], //表格数据
      listTotal: 0, //列表总数据
      pageSize: 5, //每页展示条数
      page: 1, //当前页码数
      deviceModelData: [],
      ruleForm: {
        companyId: "", //公司id
        deviceNumber: "",
        deviceTypeId: "", //所选设备类型
        brandId: "", //所选设备品牌
        deviceModel: "", //所选设备型号
        productionDate: "", //日期
        pos: "", //地区
        detailedAddress: "", //地址
        status: "", //状态
        provinceCode: "", //省code
        cityCode: "", //市code
        areaCode: "", //区code
        controlId: "", //所属控制系统
        workShopName: "", //车间名称
      },
      rules: {
        deviceNumber: [
          {
            required: true,
            validator: validateId,
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: ["blur", "change"],
          },
        ],
        deviceTypeId: [
          { validator: validateType, trigger: ["blur", "change"] },
        ],
        brandId: [{ validator: validateBrand, trigger: ["blur", "change"] }],
        deviceModel: [
          { validator: validateModel, trigger: ["blur", "change"] },
        ],
        controlId: [
          {
            required: true,
            message: "选择区",
            trigger: ["blur", "change"],
          },
        ],
        productionDate: [
          { validator: validateDate, trigger: ["blur", "change"] },
        ],
        pos: [{ validator: validatePos, trigger: ["blur", "change"] }],
        detailedAddress: [
          { validator: validateAddress, trigger: ["blur", "change"] },
        ],
        status: [{ validator: validateState, trigger: ["blur", "change"] }],
        workShopName: [
          { validator: validateWorkShop, trigger: ["blur", "change"] },
        ],
        provinceCode: [
          {
            required: true,
            message: "请选择业务经理",
            trigger: ["blur", "change"],
          },
        ],
      },
      checkCompMsg: [], //所选中公司的信息
      company: [], //所有公司
      comps: [], //设备所有类型
      controls: [], //设备控制系统
      brandData: [], //设备所有品牌
      modelData: [], //设备所有型号
      posData: [], //设备所有地区
      stateData: [], //设备所有状态
      workShopData: [], //设备车间
      compData: [],
      flag: true, //防止重复点击
      // 地区选择器
      props: { value: "id", label: "fullname", children: "areaTrees" },
      options: [],
      posValue: [],

      systemNumber: 0, //系统编号  自动生成

      // 设备类型选择器
      props1: { value: "id", label: "typeName", children: "childrenList" },
      options1: [],
      posValue1: [],
      choseType: [],
      // 图片上传

      fileList: [],

      objData: {
        OSSAccessKeyId: "", //Bucket拥有者的AccessKey ID
        policy: "", //Policy规定了请求表单域的合法性
        Signature: "", //	根据AccessKey Secret和Policy计算的签名信息
        key: "", //	上传Object的名称  文件名
        host: "", //后台返回  类似https://tulingmall-xushu.com
        dir: "", //上传地址   类似tuling-test/2021-02   用户上传文件时指定的前缀 后台加过斜杠则不用再加
      },

      showConText: {},

      upImg: [], //上传后所有的图片

      upVideo: [], //上传后所有的视频

      videodialogVisible: false,
      dialogImageUrl: "",

      showAddSource: true, //添加按钮显示隐藏
    };
  },

  mounted() {},

  created() {},
  methods: {
    // 图片上传方法
    videoSuccess(res, file, fileList) {
      this.findvideocover(file.url, file);
    },

    //截取视频第一帧作为播放前默认图片
    findvideocover(url, file) {
      // const  video = document.getElementById("myvideo"); // 获取视频对象
      const video = document.createElement("video"); // 也可以自己创建video
      // video.innerHTML = "111";
      video.src = url; // url地址 url跟 视频流是一样的

      // var canvas = document.getElementById('mycanvas') // 获取 canvas 对象
      var canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧

      video.oncanplay = () => {
        console.log(video.clientWidth, video.clientHeight);
        canvas.width = video.clientWidth ? video.clientWidth : 320; // 获取视频宽度
        canvas.height = video.clientHeight ? video.clientHeight : 320; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        this.videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        file.url = this.videoFirstimgsrc;
        // base64转成bolb文件
        const fileBolb = this.base64toFile(
          this.videoFirstimgsrc,
          this.getFileName(file)
        );
        // 把首图上传生成云上地址
        this.getFirstPngUrl(fileBolb);
        video.remove();
        canvas.remove();
      };
    },

    base64toFile(baseUrl, filename = "file") {
      let arr = baseUrl.split(",");
      let type = arr[0].match(/:(.*?);/)[1]; // 解锁图片类型
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let bufferArray = new Uint8Array(n);
      while (n--) {
        bufferArray[n] = bytes.charCodeAt(n);
      }
      return new File([bufferArray], filename, { type });
    },

    getFirstPngUrl(file) {
      const fd = new FormData();
      fd.append("file", file);
      // this.$post为我自己封装的axios的post方法。可直接用axios的post方法
      // this.$axios.post(this.showConText.host, fd).then((res) => {
      //   this.videoFirstimgsrc = res.url;
      //   this.videoWriteFileList[0].url = res.url;
      // });
    },

    getFileName(file) {
      const type = file.raw.type.split("/")[1];
      let name = "";
      if (type) {
        name = file.raw.name.substring(0, file.raw.name.indexOf(`.${type}`));
      }
      return `${name}.png`;
    },
    // handleSuccess(res, file, fileList) {
    // console.log("上传成功了", res, file, fileList);
    // console.log(this.fileList);
    // },

    progressFun() {
      // console.log("正在上传");
    },

    // 图片上传前函数
    beforeUploadFun(file) {
      console.log("上传前函数", file);
      // 如果用户第一次上传了一张图片  那么第二次一定也要是一张图片  否则提示用户

      if (this.upImg.length) {
        // 用户已经不是第一次上传了  判断一定要是图片才可以
        // var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
        var fileType = file.type.split("/")[0];
        // let res = imglist.some((item) => {
        //   return item == fileType;
        // });
        // console.log(res, "结果");
        if (fileType != "image") {
          // 上传的不是图片
          // console.log("结果是图片");
          this.$message.info("请继续上传图片");
          this.fileList.pop();
          return false;
        }
      }

      return new Promise((rs, rj) => {
        //var filename = file.name;
        this.$API.getOssConfig().then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            this.objData.OSSAccessKeyId = this.showConText.accessid;
            this.objData.policy = this.showConText.policy; //不确定
            this.objData.Signature = this.showConText.signature; //不确定
            this.objData.host = this.showConText.host;
            var suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            var filename = this.showConText.fileName + "." + suffix;
            this.objData.key = filename; //不确定
            //this.objData.success_action_status = "200";
            //this.objData.dir = this.showConText.fileName;
            // console.log("上传成功的文件访问路径:");
            //console.log(`${this.objData.host}/${this.objData.dir}/${filename}`);
            // file.viewUrl = `${this.objData.host}/${this.objData.dir}/${filename}`;
            var videaUrl = this.objData.host + "/" + filename;
            console.log(videaUrl, "videaurl");

            // 如果用户上传的是视频  控制只能上传一个
            // 匹配 视频
            var urlArr = videaUrl.split(".");
            let houzhui = urlArr[urlArr.length - 1];
            // 视频格式
            var videolist = ["mp4", "m2v", "mkv"];
            let result = videolist.some(function (item) {
              return item == houzhui;
            });
            // 图片格式
            var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
            let resultImg = imglist.some(function (item) {
              return item == houzhui;
            });
            // 上传的是视频的处理
            if (result) {
              // result = "video";
              // return result;
              // 上传的是视频
              this.showAddSource = false;
              this.upVideo.push(videaUrl);
            }

            // 上传的是图片的处理
            if (resultImg) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              // console.log("上传的是图片");
              this.upImg.push(videaUrl);
            }

            // 如果用户上传的是图片  控制可以上传三个
            // 匹配 视频
            // var urlArr = videaUrl.split(".");
            // let houzhui = urlArr[urlArr.length - 1];
            // var videolist = ["mp4", "m2v", "mkv"];
            // let result = videolist.some(function (item) {
            //   return item == houzhui;
            // });
            // if (result) {
            //   // result = "video";
            //   // return result;
            //   // 上传的是视频
            //   this.showAddSource = false;
            // }

            // this.upImg.push(videaUrl);
            if (this.upImg.length == 3) {
              this.showAddSource = false;
            }
            console.log(this.upImg);
            rs(true);
          }
        });
      });
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!this.flag) {
          return;
        }
        if (valid) {
          console.log("提交了");

          // 对地址的处理
          // console.log("pos", this.posData);
          // if (this.posData.length) {
          //   this.posData.forEach((item) => {
          //     if (this.ruleForm.pos == item.value) {
          //       let { provinceCode, cityCode, areaCode } = item;
          //       this.ruleForm = {
          //         ...this.ruleForm,
          //         ...{ provinceCode, cityCode, areaCode },
          //       };
          //     }
          //   });
          // }
          //对公司信息的处理
          let { id, contacts, contactsPhone } = this.checkCompMsg;
          this.ruleForm.companyId = id;
          this.ruleForm.customerContact = contacts;
          this.ruleForm.customerPhone = contactsPhone;
          this.ruleForm.systemNumber = this.systemNumber;
          (this.ruleForm.deviceTypeParentId = this.choseType[0]),
            (this.ruleForm.deviceTypeId = this.choseType[1]);
          this.flag = false;

          // 编辑接口
          let params = { ...this.ruleForm };
          // console.log("params", params);
          // return;

          this.$API.addAdd(params).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "新增成功",
                type: "success",
                duration: 1000,
              });
              setTimeout(() => {
                this.flag = true;
                this.$router.push("/device");
              }, 800);
            } else {
              this.flag = true;
              this.$message.info(res.msg);
            }
          });
          // this.$router.push("/device");
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// 表格
.table-box {
  margin-bottom: 23px;
  // min-height: 238px;
  .handle {
    ul {
      padding: 0 20px;
      display: flex;
      align-items: center;
    }
    li {
      float: left;
      font-size: 14px;
      font-weight: 400;
      color: #2a89ff;
      line-height: 22px;
    }
    .line {
      width: 0px;
      height: 13px;
      border: 1px solid #c6ccda;
      margin: 4px 4px;
    }
  }
}
.rightlevel {
  .hea {
    width: 900px;
    height: 57px;
    background: #f9f9f9;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #333333;
  }
  .l {
    display: flex;
    align-items: center;
    img {
      width: 4px;
      height: 14px;
      margin-right: 10px;
    }
  }
  .bodu {
    box-sizing: border-box;
    padding: 32px 40px;
  }
  .el-form-item {
    display: flex;
    // flex-direction: column;
  }
  .item {
    width: 300px;
    .el-select {
      width: 360px !important;
    }
  }
  .el-form {
    display: flex;
    flex-wrap: wrap;
  }
  .right {
    // width: 20px;
  }
  .el-cascader {
    width: 360px !important;
  }
  .item1 {
    width: 230px;
  }
  .item2 {
    width: 790px;
  }
  .right1 {
    width: 56px;
  }
  .item3 {
    width: 790px;
    height: 150px;
  }
  .upload {
    height: 80px !important;
  }
  .upload .el-form-item__label {
    margin-right: 20px;
  }
  .add-upload-box {
    width: 83px !important;
    height: 83px !important;
    line-height: 83px !important;
    border: 1px dotted #999;
  }
  .el-upload--picture-card {
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    // opacity: 0px;
    color: #ffffff;
    // width: 83px !important;
    // height: 83px !important;
    // line-height: 83px !important;
    // display: none;
  }
  .add-upload-box {
    width: 83px !important;
    height: 83px !important;
    line-height: 83px !important;
    border: 1px dotted #999;
  }
  .el-upload--picture-card {
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    // opacity: 0px;
    color: #ffffff;
    // width: 83px !important;
    // height: 83px !important;
    // line-height: 83px !important;
    // display: none;
  }
  .el-upload-list__item {
    width: 83px !important;
    height: 83px !important;
  }
  .el-upload-list__item-actions {
    width: 83px !important;
    height: 83px !important;
  }
  .el-upload-list__item-thumbnail {
    width: 83px !important;
    height: 83px !important;
  }

  .img {
    width: 83px;
    height: 83px;
    margin-left: 21px;
  }
}
</style>
